import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import { initI18n } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import AppLoaded from '../../../common/components/app-loaded';
import TagCloud from '../tag-cloud';
import TagCloudNew from '../tag-cloud/tag-cloud-new';
import styles from './app-root.scss';
import { flowRight } from 'lodash';
import { getIsTagCloudWidgetAdapterEnabled } from '../../aggregated-tag-cloud/selectors';

class AppRoot extends React.Component {
  getI18nConfig = () => {
    const { language, translations } = this.props;
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { isTagCloudWidgetAdapterEnabled } = this.props;

    if (isTagCloudWidgetAdapterEnabled) {
      return (
        <ResponsiveListener>
          <div className={styles.baseStyles}>
            <TagCloudNew />
            <AppLoaded />
          </div>
        </ResponsiveListener>
      );
    }

    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <I18nextProvider i18n={i18n}>
          <div className={styles.baseStyles}>
            <TagCloud />
            <AppLoaded />
          </div>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isTagCloudWidgetAdapterEnabled: getIsTagCloudWidgetAdapterEnabled(state),
});

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
